import React, {lazy, Suspense, useEffect, useState} from 'react';
import './home.css';
import axios from "axios";
import {useTranslation} from "react-i18next";
const HomeSlider = lazy(() => import('./slider/HomeSlider'));
const Filtration = lazy(() => import('./filtration/Filtration'));
const ProductsYouNeed = lazy(() => import('./products-you-need/ProductsYouNeed'));
const CleansyntoTechnology = lazy(() => import('./cleansynto-technology/CleansyntoTechnology'));
const NewsHome = lazy(() => import('./news-home/NewsHome'));


const Home = () => {
    const [HomeFetch, setHomeFetch] = useState([]);
    const {i18n} = useTranslation();

    useEffect(() => {
        fetchHome();
    }, [i18n.language])


    const setOptions = (url) => {
        let options;
        return options = {
            url: url,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Language': i18n.language === 'ar' ? 'ar-sy' : i18n.language
            },
        };
    }

    const fetchHome = () => {
        axios(setOptions(`${process.env.REACT_APP_mainEndPoint}/news`)).then(response => {
            setHomeFetch([response.data.data[response.data.data.length - 1],response.data.data[response.data.data.length - 2],response.data.data[response.data.data.length - 3]]);
        }).then(() => {
        })

    }



    return (
        <div className="homepage-container fadein-container">
            <HomeSlider/>
            <Filtration/>
            <ProductsYouNeed/>
            <CleansyntoTechnology/>
            <NewsHome dataFetch={HomeFetch} />
        </div>

    );

}

export default Home
