import React, { lazy, useEffect, useState, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Home from "./components/home/Home";
import Navbar from "./components/layout/navbar/NavbarMenu";
import Footer from "./components/layout/footer/Footer";

import { useTranslation } from "react-i18next";
import Loader from "./components/layout/loader/Loader";

import CookieMessage from "./components/layout/cookie-message/CookieMessage";

const Cleansynto = lazy(() => import("./components/cleansynto/cleansynto"));
const About = lazy(() => import("./components/about/About"));
const Empty = lazy(() => import("./components/layout/404/Empty"));
const EnvironmentalPolicy = lazy(() =>
  import("./components/environmental-policy/environmental-policy")
);
const Agents = lazy(() => import("./components/agents/agents"));
const Contact = lazy(() => import("./components/contact/Contact"));
const NewsList = lazy(() => import("./components/news-list/NewsList"));
const NewsDetails = lazy(() => import("./components/news-details/NewsDetails"));
const ProductsList = lazy(() =>
  import("./components/products-list/ProductsList")
);
const ProductDetails = lazy(() =>
  import("./components/product-details/productDetails")
);
const ProductSearch = lazy(() =>
  import("./components/product-search/ProductSearch")
);
const Usvo = lazy(() => import("./components/usvo/Usvo"));
const PrivacyPolicy = lazy(() =>
  import("./components/privacy-policy/PrivacyPolicy")
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const { i18n } = useTranslation();
  const [ShowFinder, setShowFinder] = useState(false);
  const [AgentMap, setAgentMap] = useState(null);

  useEffect(() => {
    if (i18n.language === "ar" || i18n.language === "ku") {
      const cssUrl = "/css/rtl.css";
      addStyle(cssUrl);
    } else {
      removeStyle();
    }
  }, [i18n.language]);

  const addStyle = (url) => {
    const style = document.createElement("link");
    const isThereRtl = document.getElementsByClassName("rtl_style");
    style.href = url;
    style.rel = "stylesheet";
    style.classList = "rtl_style";
    style.async = true;
    if (isThereRtl.length < 1) {
      document.head.appendChild(style);
    }
  };

  const removeStyle = () => {
    const style = document.getElementsByClassName("rtl_style");
    if (style[0]) {
      style[0].parentNode.removeChild(style[0]);
    }
  };

  const toggleFinder = (toggleFind) => {
    setShowFinder(toggleFind);
  };

  const toggleAgentMap = (AgentNewMap) => {
    setAgentMap(AgentNewMap);
  };

  return (
    <Router>
      <Navbar
        ShowFinder={toggleFinder}
        isShowed={ShowFinder}
        AgentMaper={toggleAgentMap}
      />
      <div className="page-content">
        <Suspense fallback={<Loader />}>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about/" component={About} />
            <Route path="/environmental/" component={EnvironmentalPolicy} />
            <Route path="/cleansynto/" component={Cleansynto} />
            <Route path="/contact/" component={Contact} />
            <Route
              path="/agents/"
              render={(props) => <Agents {...props} AgentMap={AgentMap} />}
            />
            <Route path="/news-list/" component={NewsList} />
            <Route path="/news-details/:id" component={NewsDetails} />
            <Route
              path="/products-list/:id"
              render={(props) => (
                <ProductsList {...props} ShowFinder={toggleFinder} />
              )}
            />
            <Route path="/product-search/:name" component={ProductSearch} />
            <Route path="/product-details/:id" component={ProductDetails} />
            <Route path="/usvo" component={Usvo} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="*" component={Empty} />
          </Switch>
        </Suspense>
      </div>
      <CookieMessage />
      <Footer />
    </Router>
  );
};

export default App;
