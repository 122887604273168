import React, {useEffect, useState} from 'react';
import {Button, Dropdown, DropdownButton, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import i18next from "i18next";

const OilFinderForm = (props) => {
    const {t} = useTranslation();
    const [FirstSelect, setFirstSelect] = useState([t("select_your_vehicle")]);
    const [SecondSelect, setSecondSelect] = useState([t("select_your_make")]);
    const [ThirdSelect, setThirdSelect] = useState([t("select_your_model")]);
    const [FourthSelect, setFourthSelect] = useState([t("select_your_type")]);
    const [SearchID, setSearchID] = useState(null);
    const [SecondSelectStatus, setSecondSelectStatus] = useState(1);
    const [ThirdSelectStatus, SetThirdSelectStatus] = useState(1);
    const [FourthSelectStatus, setFourthSelectStatus] = useState(1);
    const [Vehicles, setVehicle] = useState([]);
    const [Markers, setMarker] = useState([]);
    const [Models, setModel] = useState([]);
    const [Types, setType] = useState([]);
    const [SearchContent, setSearchContent] = useState([]);
    const [IsSearchDisabled, setIsSearchDisabled] = useState(true);
    const [validated, setValidated] = useState(false);
    const [SearchResult, setSearchResult] = useState([]);


    i18next.on('languageChanged', function (lng, ns, msg) {
        setFirstSelect(t("select_your_vehicle"));
        setSecondSelect(t("select_your_make"));
        setThirdSelect(t("select_your_model"));
        setFourthSelect(t("select_your_type"));
    })


    useEffect(() => {
        fetchContent(1);
    }, []);

    const setOptions = (url) => {
        let options;
        return options = {
            url: url,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
        };
    }

    const fetchContent = (type, UniqueNumber) => {
        switch (type) {
            case 1:
                axios(setOptions('https://oilguide.ravenol.de/oilguideapi/en/getVehicles')).then(response => {
                    setVehicle(response.data.response);
                }).then(() => {

                })
                break;
            case 2:
                setSecondSelectStatus(2);
                setSecondSelect([t("select_your_make")]);
                setThirdSelect([t("select_your_model")]);
                setFourthSelect([t("select_your_type")]);
                axios(setOptions(`https://oilguide.ravenol.de/oilguideapi/en/getMakers/${UniqueNumber}`)).then(response => {
                    setMarker(response.data.response);
                }).then(() => {
                    setSecondSelectStatus(3);
                })
                break;
            case 3:
                SetThirdSelectStatus(2);
                setThirdSelect([t("select_your_model")]);
                setFourthSelect([t("select_your_type")]);
                axios(setOptions(`https://oilguide.ravenol.de/oilguideapi/en/getModels/${UniqueNumber}`)).then(response => {
                    setModel(response.data.response);
                }).then(() => {
                    SetThirdSelectStatus(3);
                })
                break;
            case 4:
                setFourthSelectStatus(2);
                setFourthSelect([t("select_your_type")]);
                axios(setOptions(`https://oilguide.ravenol.de/oilguideapi/en/getTypes/${UniqueNumber}`)).then(response => {
                    setType(response.data.response);
                }).then(() => {
                    setFourthSelectStatus(3);
                })
                break;
            default:
                console.log('Error in API structure');
        }

    }

    const handleVehicle = (e) => {
        let vehicles = e.match(/([^,]*),(.*)/);
        setFirstSelect(vehicles[2]);
        fetchContent(2, vehicles[1]);
    }

    const handleMarker = (e) => {
        let markers = e.match(/([^,]*),(.*)/);
        setSecondSelect(markers[2]);
        fetchContent(3, markers[1]);
    }

    const handleModel = (e) => {
        let models = e.match(/([^,]*),(.*)/);
        setThirdSelect(models[2]);
        fetchContent(4, models[1]);
    }

    const handleType = (e) => {
        let types = e.match(/([^,]*),(.*)/);
        setFourthSelect(types[2]);
        setSearchID(types[1])
    }

    const changeSearch = (event) => {
        setSearchContent(event.target.value)
        if (event.target.value) {
            setIsSearchDisabled(false)
        } else {
            setIsSearchDisabled(true)
        }
    }


    const searchForm = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const searchText = e.target.elements.searchText.value;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {

        }
    }

    const selectSearch = (e) => {
        axios(setOptions(`https://oilguide.ravenol.de/oilguideapi/en/getTypeListFromInstantSearch/${e.target.value}`)).then(response => {
            setSearchResult(response.data.response.items);
        })
    }

    return (
        <div className={`oil-finder-form ${props.isHome ? 'home-oil-finder' : ''}`}>
            {!props.isHome ?
                <>
                    <p className={`oil-finder-mini-title text-white ${props.isHome ? 'd-none' : ''}`}>{t("direct_search")}</p>
                    <DropdownButton className="oil-finder-select direct-search-select"
                                    title={<input type="text" onChange={selectSearch}
                                                  placeholder={t("start_typing_to_search_for_a_vehicle")}/>
                                    }>
                        <Dropdown.Item style={{textAlign: 'start'}} as="button" className="oil-finder-select-option oil-finder-direct-select-option" disabled>
                            <a>
                                {t("start_typing")}
                            </a>
                        </Dropdown.Item>
                        {SearchResult?.map((res) => (
                            <Dropdown.Item as="button" className="oil-finder-select-option oil-finder-direct-select-option" key={res.attributes.id}
                                           eventKey={[res.attributes.id, res.attributes.result]}>
                                <Link to={`/products-list/${res.attributes.id}`} className="oil-finder-direct-select-option-link">
                                    {res.attributes.result}
                                </Link>
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <div className="oil-finder-or text-white text-center mt-4">
                        <span>{t("OR")}</span>
                    </div>
                </>
                : ''}
            <p className={`oil-finder-mini-title text-white quick-selection ${props.isHome ? 'd-none' : ''}`}>{t("quick_selection")}</p>
            <DropdownButton className="oil-finder-select" value={FirstSelect} title={FirstSelect}
                            onSelect={handleVehicle}>
                {Vehicles.map((vehicle) => (
                    <Dropdown.Item className="oil-finder-select-option" key={vehicle.value}
                                   eventKey={[vehicle.value, vehicle.label]}>{vehicle.label}</Dropdown.Item>
                ))}
            </DropdownButton>
            <DropdownButton disabled={SecondSelectStatus !== 3 || FirstSelect === t("select_your_vehicle")}
                            className={"oil-finder-select" + (SecondSelectStatus === 2 ? " select-loading" : "")}
                            title={SecondSelect} onSelect={handleMarker}>
                {
                    Markers ?
                        Markers.map((marker) => (
                            <Dropdown.Item className="oil-finder-select-option" key={marker.value}
                                           eventKey={[marker.value, marker.label]}>
                                <img className="marker-img"
                                     src={`data:image/png;base64,${marker.images}`}
                                     alt=""/>
                                {marker.label}
                            </Dropdown.Item>
                        )) : null
                }
            </DropdownButton>
            <DropdownButton disabled={ThirdSelectStatus !== 3 || SecondSelect === t("select_your_make")}
                            className={"oil-finder-select" + (ThirdSelectStatus === 2 ? " select-loading" : "")}
                            title={ThirdSelect}
                            onSelect={handleModel}>
                {
                    Models ?
                        Models.map((model) => (
                            <Dropdown.Item className="oil-finder-select-option" key={model.value}
                                           eventKey={[model.value, model.label]}>{model.label}</Dropdown.Item>
                        )) : null
                }
            </DropdownButton>
            <DropdownButton disabled={FourthSelectStatus !== 3 || ThirdSelect === t("select_your_model")}
                            className={"oil-finder-select" + (FourthSelectStatus === 2 ? " select-loading" : "")}
                            title={FourthSelect}
                            onSelect={handleType}>
                {
                    Types ?
                        Types.map((type) => (
                            <Dropdown.Item className="oil-finder-select-option" key={type.value}
                                           eventKey={[type.value, type.label]}>{type.label}</Dropdown.Item>
                        )) : null
                }
            </DropdownButton>
            <Link
                className={"oil-finder-submit" + (FourthSelectStatus === 3 && FourthSelect !== t("select_your_type") ? "" : " oil-finder-disable")}
                to={`/products-list/${SearchID}`}>
                <span>{t("search")}</span>
                {!props.isHome ? <img src="/images/search-icon.svg" alt=""/> : null}
            </Link>
        </div>

    );
}

export default OilFinderForm
