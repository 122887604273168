import React, {useContext, useEffect, useState} from "react";
import {Navbar, Nav, Card, Accordion, AccordionContext, Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './navbar.css'
import OilFinder from "./oil-finder/OilFinder";
import {faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from 'react-i18next';
import LanguageSelector from "../language-selector/LanguageSelector";
import axios from "axios";

const NavbarMenu = (props) => {
    const [FixedMenu, setFixedMenu] = useState(false);
    const [ShowAgentsMenu, setShowAgentsMenu] = useState(false);
    const [ShowCompany, setShowCompany] = useState(['']);
    const {t} = useTranslation();
    const [Agents, setAgents] = useState([]);
    const {i18n} = useTranslation();

    useEffect(() => {
        fetchAgents();
    }, [i18n.language]);


    const setOptions = (url) => {
        let options;
        return options = {
            url: url,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Language':i18n.language === 'ar' ? 'ar-sy' : i18n.language
            },
        };
    }

    const fetchAgents = () => {
        axios(setOptions(`${process.env.REACT_APP_mainEndPoint}/agents`)).then(response => {
            setAgents(response.data.data);
        });

    }

    function ContextAwareToggle({children, eventKey, callback}) {
        const currentEventKey = useContext(AccordionContext);

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <FontAwesomeIcon className={isCurrentEventKey ? 'rotate-chevron' : ''} icon={faChevronDown}/>

        );
    }

    const handleScroll = () => {
        window.scrollY > 120 ? setFixedMenu(true) : setFixedMenu(false)
    }

    window.addEventListener('scroll', handleScroll);

    return (
        <div className={"menu-container" + (FixedMenu ? " fixed-menu" : "") + (props.isShowed ? ' show-finder' : '')}>
            <Navbar collapseOnSelect bg="transparent" expand="xl">
                <Link to='/'>
                    <img className="menu-logo" src="/images/logo.svg" alt=""/>
                    <div className="logo-text">{t("iraq")}</div>
                </Link>
                <button className="menu-oilfinder-button d-flex align-items-center d-xl-none"
                        onClick={() => props.ShowFinder(true)}>
                    <span className="d-none d-xl-block">{t("oil_finder")}</span>
                    <img className="menu-oilfinder-icon" src="/images/search-icon.svg" alt=""/>
                </button>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="">
                        <a className={`nav-link d-none d-xl-block ${ShowCompany}`}
                           onMouseEnter={() => setShowCompany(['show'])}
                           onMouseLeave={() => setShowCompany('')}>
                            <div className="nav-link-text">{t("company")}</div>
                        </a>
                        <div className="nav-link d-block d-xl-none">
                            <Accordion className="menu-responsive-accordion">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        {t("company")}
                                        <ContextAwareToggle eventKey="0">
                                        </ContextAwareToggle>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Link to='/about'>
                                                            <Navbar.Toggle>
                                                                <Card className="menu-card"
                                                                      onClick={() => setShowCompany([''])}>
                                                                    <div className="card-body card-image-container">
                                                                        <Card.Img variant="top"
                                                                                  src="/images/about-menu-image.jpg"/>
                                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                                    </div>
                                                                    <Card.Body className="menu-card-body">
                                                                        <Card.Title>{t("ABOUT")}</Card.Title>
                                                                        <Card.Text>{t("about_menu")}</Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Navbar.Toggle>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to='/contact'>
                                                            <Navbar.Toggle>
                                                                <Card className="menu-card"
                                                                      onClick={() => setShowCompany([''])}>
                                                                    <div className="card-body card-image-container">
                                                                        <Card.Img variant="top"
                                                                                  src="/images/contact-menu-image.jpg"/>
                                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                                    </div>
                                                                    <Card.Body className="menu-card-body">
                                                                        <Card.Title>{t("contact_us_menu")}</Card.Title>
                                                                        <Card.Text>{t("contact_menu")}</Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Navbar.Toggle>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to='/usvo'>
                                                            <Navbar.Toggle>
                                                                <Card className="menu-card"
                                                                      onClick={() => setShowCompany([''])}>
                                                                    <div className="card-body card-image-container">
                                                                        <Card.Img variant="top"
                                                                                  src="/images/usvo-menu-image.jpg"/>
                                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                                    </div>
                                                                    <Card.Body className="menu-card-body">
                                                                        <Card.Title>{t("USVO")}</Card.Title>
                                                                        <Card.Text>{t("usvo_menu")}</Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Navbar.Toggle>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Link to='/cleansynto'>
                                                            <Navbar.Toggle>
                                                                <Card className="menu-card"
                                                                      onClick={() => setShowCompany([''])}>
                                                                    <div className="card-body card-image-container">
                                                                        <Card.Img variant="top"
                                                                                  src="/images/clean-menu-image.jpg"/>
                                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                                    </div>
                                                                    <Card.Body className="menu-card-body">
                                                                        <Card.Title>{t("CLEANSYNTO")}</Card.Title>
                                                                        <Card.Text>{t("cleansynto_menu")}</Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Navbar.Toggle>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <Nav.Item>
                            <Dropdown show={ShowAgentsMenu} className="agents-drop d-none d-xl-block"
                                      onMouseOver={() => setShowAgentsMenu(true)}
                                      onMouseLeave={() => setShowAgentsMenu(false)}>
                                <Dropdown.Toggle variant="transparent" className="p-0 agentsDropdown"
                                                 id="agentsDropdown">
                                    <div className="nav-link-text">{t("agents")}</div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="agents-dropdownmenu">
                                    {Agents.map((item, number) => (
                                        <Nav.Link eventKey={number} key={number} as={Link} onClick={() => {
                                            props.AgentMaper(item);
                                            setShowAgentsMenu(false)
                                        }} to="/agents">
                                            <div className="nav-link-text">{item.agent_title}</div>
                                        </Nav.Link>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Item>
                        <div className="nav-link d-block d-xl-none">
                            <Accordion className="menu-responsive-accordion">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        {t("agents")}
                                        <ContextAwareToggle eventKey="0">
                                        </ContextAwareToggle>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="px-3 pb-1 pt-3">
                                            {Agents.map((item, number) => (
                                                <Navbar.Toggle key={number} className="mt-1 mb-1">
                                                    <Nav.Link eventKey={number} as={Link} onClick={() => {
                                                        props.AgentMaper(item);
                                                        setShowAgentsMenu(false)
                                                    }} to="/agents">
                                                        <div className="nav-link-text">{item.agent_title}</div>
                                                    </Nav.Link>
                                                </Navbar.Toggle>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>

                        <Nav.Item>
                            <Nav.Link eventKey="3" as={Link} to="/news-list">
                                <div className="nav-link-text">{t("news")}</div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
                <div className={`company-menu ${ShowCompany}`} onMouseEnter={() => setShowCompany(['show'])}
                     onMouseLeave={() => setShowCompany('')}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <Link to='/about'>
                                    <Card className="menu-card" onClick={() => setShowCompany([''])}>
                                        <div className="card-body card-image-container">
                                            <Card.Img variant="top" src="/images/about-menu-image.jpg"/>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </div>
                                        <Card.Body className="menu-card-body">
                                            <Card.Title>{t("ABOUT")}</Card.Title>
                                            <Card.Text>{t("about_menu")}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <Link to='/contact'>
                                    <Card className="menu-card" onClick={() => setShowCompany([''])}>
                                        <div className="card-body card-image-container">
                                            <Card.Img variant="top" src="/images/contact-menu-image.jpg"/>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </div>
                                        <Card.Body className="menu-card-body">
                                            <Card.Title>{t("contact_us_menu")}</Card.Title>
                                            <Card.Text>{t("contact_menu")}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <Link to='/usvo'>
                                    <Card className="menu-card" onClick={() => setShowCompany([''])}>
                                        <div className="card-body card-image-container">
                                            <Card.Img variant="top" src="/images/usvo-menu-image.jpg"/>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </div>
                                        <Card.Body className="menu-card-body">
                                            <Card.Title>{t("USVO")}</Card.Title>
                                            <Card.Text>{t("usvo_menu")}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <Link to='/cleansynto'>
                                    <Card className="menu-card" onClick={() => setShowCompany([''])}>
                                        <div className="card-body card-image-container">
                                            <Card.Img variant="top" src="/images/clean-menu-image.jpg"/>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </div>
                                        <Card.Body className="menu-card-body">
                                            <Card.Title>{t("CLEANSYNTO")}</Card.Title>
                                            <Card.Text>{t("cleansynto_menu")}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="menu-oilfinder-button d-none d-xl-flex align-items-center"
                        onClick={props.ShowFinder}>
                    <span>{t("oil_finder")}</span>
                    <img className="menu-oilfinder-icon" src="/images/search-icon.svg" alt=""/>
                </button>
            </Navbar>
            <LanguageSelector/>
            <OilFinder ShowFinder={props.ShowFinder}/>
        </div>
    )
}


export default NavbarMenu
