import React, {useEffect, useState} from 'react';
import './Footer.css';
import {Container, Form, FormControl, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import footerLogo from "../../../images/logo_footer.svg";
import footerRightLogo from "../../../images/right_img_footer.svg";
import {Link, useLocation} from 'react-router-dom';
import styled from "styled-components";
import LanguageSelector from "../language-selector/LanguageSelector";
import {useTranslation} from 'react-i18next';


const Footer = () => {
    const SideFooter = styled.div`
        background:#0057A5 url('/images/squared-bg.svg');
        font-size:15px;
        line-height:24px;
        color:#fff;
        position: relative;
     `;

    const {t} = useTranslation();

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname == "/agents") {
            setCustomFooter(" custom-footer");
        } else if (pathname == "/") {
            setCustomFooter(" home-footer");
        } else setCustomFooter('');
    }, [pathname]);

    const [CustomFooter, setCustomFooter] = useState('');


    return (
        <SideFooter className={CustomFooter}>
            <footer className={"site-footer"}>
                <Container className="yellow-part-footer">
                    {t("footer_approach")}
                </Container>

                <Container className="footer-container">
                    <div className="row first-row-mobile ">
                        <div className="col-md-4 col-sm-6 d-block d-md-none">
                            <img src={footerRightLogo} alt="logo Footer" className="img-fluid"/>
                        </div>
                        <div className="col-md-4 col-sm-6 d-block d-md-none">
                            <div className="language-picker js-language-picker float-right">
                                <LanguageSelector/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 d-block d-md-none">
                            <Form inline className="form-footer">
                                <FormControl type="text" placeholder="Search" className="mr-sm-2 search-field"/>
                                <FontAwesomeIcon icon={faSearch} className="ii"/>
                            </Form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-7 col-menu">
                            <ul className=" Social-media float-left">
                                <li><a className="facebook"
                                       href="https://www.facebook.com/Ravenoliniraq"><FontAwesomeIcon icon={faFacebookF}
                                                                                                      className="fac"/></a>
                                </li>
                                <li><a className="insta" href="https://www.instagram.com/ravenol_iraq/"><FontAwesomeIcon
                                    icon={faInstagram} className="fac"/></a></li>
                                <li><a className="linkedin"
                                       href="https://www.youtube.com/channel/UCVDUHAj4pIzpAotRqYzwXqg"><FontAwesomeIcon
                                    icon={faYoutube} className="fac"/></a></li>
                            </ul>

                            <Nav className="mr-auto menu-footer">
                                <Link className="nav-link" to='/about'>{t("about_us")}</Link>
                                <Link className="nav-link" to='/contact'>{t("contact_us_menu")}</Link>
                                <Link className="nav-link" to='/cleansynto'>{t("CLEANSYNTO")}</Link>
                                <Link className="nav-link" to='/environmental'>{t("enviromental_policy")}</Link>
                                <Link className="nav-link" to='/privacy-policy'>{t("privacy_policy")}</Link>

                            </Nav>

                        </div>
                        <div className="col-sm-12 col-md-5 d-none d-md-block">
                            <img src={footerRightLogo} alt="logo Footer" className="img-fluid float-right"/>
                        </div>
                    </div>
                    <hr className="d-none d-md-block"/>
                    <div className="row copyright-row">
                        <div className="col-md-4 col-sm-6">
                            <div className="language-picker js-language-picker d-none d-md-block">
                                <LanguageSelector/>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-8 text-center logo-f">
                            <p className="copyright-text">{t("©_2021_Ravensberger_Schmierstoffvertrieb_GmbH")}</p>
                        </div>

                        <div className="col-md-4 col-sm-4 d-md-flex justify-content-end align-items-center">
                            <div>
                                <img src={footerLogo} alt="logo Footer" className="img-fluid logo-footer"/>
                                <div className="footer-made-by">
                                    <div className="text-white">Made with love by</div>
                                    <a href="https://osoustech.com/" >
                                        OSOUS TECHNOLOGY </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </footer>
        </SideFooter>
    )
}

export default Footer
