import React, { useRef, useEffect } from "react";
import "./cookie-message.css";
import { useTranslation } from "react-i18next";

const CookieMessage = () => {
  // use this const for translation
  const { t } = useTranslation();

  // determine the storage type cooies or session or local storage
  const storageType = localStorage;

  // set a name for the cookie
  const consentPropertyName = "cookie_consent";
  const consentPopup = useRef();

  // apply this function on reject button click to clear cookies and set the value to false
  const rejectFn = () => {
    consentPopup.current.classList.add("hidden");
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        var p = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
    storageType.setItem(consentPropertyName, false);
  };
  // apply this function on accept cookie buttton to set cookie value to true
  const acceptFn = () => {
    saveToStorage(storageType);
    consentPopup.current.classList.add("hidden");
  };

  const shouldShowPopup = !JSON.parse(
    localStorage.getItem(consentPropertyName)
  ); // use localstorage to get the item value to show the popup if the cookie doesn't exist
  const saveToStorage = () => storageType.setItem(consentPropertyName, true); // this const will save the cookie if the user hit accept

  useEffect(() => {
    if (shouldShowPopup) {
      consentPopup.current.classList.remove("hidden");
    }
    window.onload = function () {
      if (JSON.parse(localStorage.getItem(consentPropertyName)) === false) {
        rejectFn();
      }
    };
  }, []);

  return (
    <div id="cookie-container" className="hidden" ref={consentPopup}>
      <p>
        {t(
          "This website uses cookies 🍪 to ensure you get the best experience, deliver personalized content and analyze our traffic as explained in our "
        )}{" "}
        <a href="/privacy-policy" className="privacy-link">
          {t("Privacy Policy")}
        </a>
      </p>
      <div className="btn-section d-flex">
        <a role="button" onClick={rejectFn}>
          <span className="reject-btn cookie-btn">{t("Reject")}</span>
        </a>
        <a role="button">
          <span className="accept-btn cookie-btn" onClick={acceptFn}>
            {t("Accept")}
          </span>
        </a>
      </div>
    </div>
  );
};

export default CookieMessage;
