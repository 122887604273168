import React, { useEffect, useState } from 'react';
import { Nav, NavDropdown } from "react-bootstrap";
import i18next from "i18next";

const LanguageSelector = () => {
    const languageMap = {
        en: { src: "/images/english.svg", dir: "ltr", active: true, label: "En" },
        ar: { src: "/images/arabic.svg", dir: "rtl", active: false, label: "Ar" },
        ku: { src: "/images/kurdish.svg", dir: "rtl", active: false, label: "Ku" },
    };
    const selected = localStorage.getItem("i18nextLng") || "en";
    const [menuAnchor, setMenuAnchor] = useState(null);

    useEffect(() => {
        document.body.dir = languageMap[selected].dir;
    }, [menuAnchor, selected]);

    return (
        <>
            <NavDropdown title={<img className="menu-dropdown-flag" src={`/images/${selected}.svg`} alt="" />}
                id="basic-nav-dropdown">
                            <span className="lbl-lang-s">{selected}</span>
                {Object.keys(languageMap)?.map((item, number) => (
                    item !== selected ?
                        <Nav.Link eventKey={number} className="nav-link lang" key={item} onClick={() => {
                            i18next.changeLanguage(item);
                            setMenuAnchor(null);
                        }}>
                            <img className="menu-dropdown-flag" src={`/images/${item}.svg`} alt="" />
                            <span className="lbl-lang">{item}</span>
                        </Nav.Link> : null
                ))} 
            </NavDropdown>

        </>
    );
}

export default LanguageSelector
