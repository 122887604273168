import React, {useEffect, useRef} from 'react';
import './OilFinder.css'
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import OilFinderForm from "../../oil-finder-form/OilFinderForm";
import {Form} from "react-bootstrap";

const OilFinder = (props) => {
    const {t} = useTranslation();

    const wrapperRef = useRef(null);


    useEffect(() => {
        clickFinder();
    }, []);

    const clickFinder = () => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }





    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.classList.value.includes('menu-oilfinder-button') && !event.target.classList.value.includes('product-list-change-btn') || event.target.classList.value.includes('oil-finder-submit') || event.target.classList.value.includes('oil-finder-direct-select-option-link') || event.target.classList.value.includes('direct-search-btn') || event.target.classList.value.includes('close-menu')) {
            props.ShowFinder(false);
        }
    }





    return (
        <div className="navbar-oil-finder">
            <div className="menu-oil-finder-container" ref={wrapperRef}>
                <h4 className="menu-oil-finder-title">{t("oil_finder")}</h4>
                <div className="close-menu">
                    <FontAwesomeIcon icon={faTimes}/>
                </div>
                <OilFinderForm />
            </div>
        </div>

    );
}

export default OilFinder
